import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import imgrepHeader from "../../images/bild-rep.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubProceduralImgRep() {
  window.open(
    "https://github.com/johannagranstroem/TNM097-Image-Reproduction-and-Image-Quality",
    "_blank"
  )
}

const imageReproductionPage = () => (
  <Main>
    <SEO title="Image Reproduction" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Imgrep" src={imgrepHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">IMAGE INCEPTION</p>
          <p class="project-header-text-grey">IMAGE REPRODUCTION</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; MATLAB</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-03-30</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          I worked on this project in a group of two. The projects' goal was to
          reproduce any image of several smaller images in MATLAB. The database
          consists of 200 random images in different colours and shades which
          were used to reproduce a desired original image. The original image
          and, the images in the database, were converted to CIELAB so that each
          colour channel could be compared between the two images.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubProceduralImgRep()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default imageReproductionPage
